<template>
  <v-content>
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img src="@/assets/shopping_icon.svg"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">Shopping</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
          @click.stop="$emit('set')"
          >
            すべて表示
          </v-btn>
        </v-col>
      </v-row>

      <v-card width="295px" height="463px" class="elevation-6">
        <!-- <v-img src="@/assets/shopping_905017_r3@2x.png" @click.stop="$emit('set')"></v-img> -->
        <v-img src="https://www.y-shokukobo.com/philosophy/images/photo_philosophy02.jpg" @click.stop="$emit('set')"></v-img>
      </v-card>
    </div>
  </v-content>
</template>

<script>
export default {
  props: [],
  created() {
  },
  methods: {
  },
}
</script>