<template>
  <v-content>
    <!-- リサーブ系 -->
    <div v-if="booking.multi_flg == '1'" class="px-3 pb-4">
      <v-row align="end" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px;height: 30px"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">RESERVE</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor" @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
            すべて表示
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        <v-card width="295px" height="403px" class="elevation-6">
          <div style="width: 100%; height: 171px; overflow-y: auto;" class="maincolor-bg_opacity">
            <div style="font: normal 11px/18px 'Roboto';" class="ma-4">
              <p style="font: bold 13px/18px 'Roboto';" v-if="booking.data[0].title" class="mb-4">{{booking.data[0].title}}</p>
              <p v-if="booking.data[0].description" v-html="booking.data[0].description" class="mb-4"></p>
              <p style="font: bold 13px/18px 'Roboto'; white-space:pre-wrap; word-wrap:break-word;" v-html="booking.data[0].attention_for_temporary_booking"></p>
            </div>
          </div>
          <p class="mx-4 my-3 maincolor" style="font: bold 12px/20px 'Roboto';">
            予約可能なコースはこちら
          </p>
          <div style="overflow: auto; height: 20%;">
            <p v-for="(menu_name, index) in booking.data[0].menu_data" :key="index" class="mx-4 mb-0" style="font: bold 14px/20px 'Roboto'; color: #333333;">
              ・{{menu_name}}
            </p>
          </div>
          <p class="mx-4 mt-5 mb-0" style="font: normal 9px/13px 'Roboto';">対象店舗：{{booking.data[0].shop_name}}</p>
          <v-row align="start" justify="center" no-gutters class="mt-5">
            {{getDetailUrl()}}
            <v-btn rounded dark width="200px" class="maincolor-bg" style="font: bold 14px/24px 'Roboto';"
            @click="$router.push({ name: 'booking', params: { id: booking.data[0].id }})">
              予約詳細を見る
            </v-btn>
          </v-row>
        </v-card>
        <div class="text-center mt-4">
          <p class="mb-0 maincolor" style="font: bold 11px/16px 'Roboto'; ">現在予約受付申し込み可能な店舗が複数あります</p>
          <a style="font: bold 9px/16px 'Roboto';" class="maincolor" @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">すべて表示</a>
        </div>
      </v-card>
    </div>

    <div v-if="booking.multi_flg == 0" class="px-3 pb-4">
      <v-row align="end" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img src="@/assets/topc2.png" /></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">RESERVE</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn class="maincolor" rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF"  @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
            すべて表示
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        <div style="width: 100%; height: 193px; overflow-y: auto;" class="maincolor-bg_opacity">
          <div style="font: normal 11px/18px 'Roboto';" class="ma-4">
            <p style="font: bold 13px/18px 'Roboto';" v-if="booking.data[0].title" class="mb-4">{{booking.data[0].title}}</p>
            <p v-if="booking.data[0].description" v-html="booking.data[0].description" class="mb-4"></p>
            <p style="font: bold 13px/18px 'Roboto'; white-space:pre-wrap; word-wrap:break-word;" v-html="booking.data[0].attention_for_temporary_booking"></p>
          </div>
        </div>
        <p class="mx-4 my-4 maincolor" style="font: bold 12px/20px 'Roboto';">
          予約可能なコースはこちら
        </p>
        <div style="overflow: auto; height: 30%;">
          <p v-for="(menu_name, index) in booking.data[0].menu_data" :key="index" class="mx-4 mb-0" style="font: bold 14px/20px 'Roboto'; color: #333333;">
            ・{{menu_name}}
          </p>
        </div>
        <v-row align="start" justify="center" no-gutters class="mt-6">
          {{getDetailUrl()}}
          <v-btn class="maincolor-bg" rounded dark width="200px" color="#FF00B1" style="font: bold 14px/24px 'Roboto';"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
            予約詳細を見る
          </v-btn>
        </v-row>
      </v-card>
    </div>

    <div v-if="booking.data.length == 0" class="px-3 pb-4">
      <v-row align="end" justify="center" no-gutters class="mt-11 mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img src="@/assets/topc2.png" /></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">RESERVE</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" style="color: #FF00B1;">
            すべて表示
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        <v-img src="@/assets/topc3.png" class="text-center">
          <v-row align="center" justify="center" style="height: 463px; font: normal 14px/22px 'Roboto'; color: #9D9D9D;">
            現在利用できる<br>予約サービスはありません
          </v-row>
        </v-img>
      </v-card>
    </div>
  </v-content>
</template>

<script>
export default {
  props: ['booking', 'menu'],
  created() {
  },
  methods: {
    getDetailUrl: function() {
      this.detail_url =  this.getDomain() + 'booking/detail?id=' + this.booking.data[0].id
    }
  },
}
</script>
