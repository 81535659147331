<template>
  <v-dialog v-model="qrDialog">
    <div class="pa-10 text-center" style="background: #fff;">
      <qrcode :value="qrdata" :options="{ width: 200, margin: 1 }"></qrcode>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: ['qrdata'],
  data() {
    return {
      qrDialog: false,
    };
  },
  methods: {
    open() {
      this.qrDialog = true;
    },
    close() {
      this.qrDialog = false;
    }
  }
}
</script>