<template>
  <v-content>
    <!-- スタンプ系 -->
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px;height:30px"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">STAMP CARD</span>
        </v-col>

        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
            すべて表示
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        <v-card width="295px" height="403px" class="elevation-6" v-if="stamp.data">
          <v-img :src="stamp.data[0].image_path" height="194px"></v-img>
          <p class="mx-4 mt-5 mb-0" style="font: bold 14px/20px 'Roboto';">
            {{stamp.data[0].title}}
          </p>
          <div class="mx-4 mt-2" style="font: normal 9px/13px 'Roboto';">
            <p class="ma-0">有効期限：{{stamp.data[0].expiration_date}}</p>
            <p class="ma-0">対象店舗：{{stamp.data[0].shop_names}}</p>
          </div>
          <v-row align="start" justify="center" no-gutters class="mt-4">
            <v-btn rounded dark width="200px" class="maincolor" style="font: bold 14px/24px 'Roboto';" @click.stop="toStampAuth(stamp.data[0].id, stamp.data[0].auth_type, stamp.data[0].shop_id)">
              STAMP GET
            </v-btn>
            <v-btn v-if="stamp.comp_ticket_flg" rounded outlined disabled x-small style="font: bold 12px/20px 'Roboto';" class="mt-5">
              獲得済みチケットを見る
            </v-btn>
          </v-row>
        </v-card>
        <v-card width="295px" height="463px" class="elevation-6" v-if="!stamp.data">
          <v-img src="@/assets/topc3.png" class="text-center">
            <v-row align="center" justify="center" style="height: 463px; font: normal 14px/22px 'Roboto'; color: #9D9D9D;">
              現在利用できる<br>スタンプカードはありません
            </v-row>
          </v-img>
        </v-card>
        <div class="text-center mt-4" v-if="stamp.data > 1">
          <p class="mb-0 maincolor" style="font: bold 11px/16px 'Roboto';">現在ご利用可能なスタンプカードが{{stamp.data.length}}枚あります</p>
          <a class="maincolor" style="font: bold 9px/16px 'Roboto';" @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">すべて表示</a>
        </div>
      </v-card>
    </div>

  </v-content>
</template>

<script>
export default {
  props: ['stamp', 'menu'],
  created() {
  },
  methods: {
    toStampAuth(id, auth_type, shop_id){
      this.loading = true
      var destination = null
      var screenID = null
      switch (auth_type) {
        case 1:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
        case 2:
          destination = 'stampAuthQrActivity'
          screenID = 'stamp_auth_qr'
          break;
        case 3:
          destination = 'stampAuthBeaconActivity'
          screenID = 'stamp_auth_beacon'
          break;
        case 4:
          destination = 'stampAuthWifiActivity'
          screenID = 'stamp_auth_wifi'
          break;
        default:
          // 20210203 NEW_DEV-1407 cyber start
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
          // 20210203 NEW_DEV-1407 cyber start
      }
      var tonative_req = {
        'destination': destination,
        'feature_id': 'stamp',
        'screen_id': screenID,
        'transition_type': 3,
        'stamp_id': id,
        'shop_id': shop_id,
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }else{
        this.loading = false
      }
    },
  },
}
</script>
