<template>
  <v-content style="width: 375px;" class="mx-auto my-0 pa-0" :style="(init.preview == 1) ? 'padding-bottom:70px;' : ''">
    <div
      class="wrap"
      :style="`background-image: url(`+init.top_background_image+`); background-size: cover; min-height:100vh;`"
    >
      <!-- logo -->
    <v-row align-content="center" justify="center" no-gutters class="logo" :style="{
        backgroundImage: 'url(' + init.top_background_image + ') !important',
        backgroundSize: 'cover !important'
    }">
      <img v-if="init.top_logo_image" :src="init.top_logo_image" style="width:auto; max-width:100%;" />
      <span v-else style="font-weight: bold; font-size: 22px;">{{init.shop_name}}</span>
    </v-row>

      <!-- background & scroll -->
      <div class="body" v-if="menu_list != null">
        <div class="slider">
          <slick ref="slick" :options="slickOptions">
            <!-- ランディングページ -->
            <TopLPage
              :menu="registration_list"
              @set="NextRouting"
            ></TopLPage>
            <div v-for="menu in menu_list.menus" :key="menu.id">
              <!-- 店舗検索 -->
              <TopShop
                v-if="menu.feature_id == 'info' && shop"
                :shop="shop"
                :menu="menu"
                :init="init"
                @set="NextRouting"
              ></TopShop>
              <!-- ポイント -->
              <TopPoint
                v-if="menu.feature_id == 'point' && point && init.access_token"
                :point="point"
                :menu="menu"
                :init="init"
                @set="NextRouting"
              ></TopPoint>
              <!-- クーポン -->
              <TopCoupon
                v-if="menu.feature_id == 'coupon' && coupon && init.access_token"
                :coupon="coupon"
                :menu="menu"
                @set="NextRouting"
              ></TopCoupon>
              <!-- スタンプ -->
              <TopCStamp
                v-if="menu.feature_id == 'stamp' && stamp && init.access_token"
                :stamp="stamp"
                :menu="menu"
                @set="NextRouting"
              ></TopCStamp>
              <!-- リサーブ系 -->
              <TopBooking
                v-if="menu.feature_id == 'booking' && booking && init.access_token"
                :booking="booking"
                :menu="menu"
                @set="NextRouting"
              ></TopBooking>
              <!-- デジチケ系 -->
              <TopDigitalTicket
                v-if="menu.feature_id == 'digital_ticket' && init.access_token"
                :digitalticket="digitalticket"
                :menu="menu"
                :init="init"
                @set="NextRouting"
              ></TopDigitalTicket>
              <!-- プッシュ通知 -->
              <TopNotification
                v-if="menu.feature_id == 'notification' && notification"
                :notification="notification"
                :menu="menu"
                @set="NextRouting"
              ></TopNotification>

              <!-- 外部リンク -->
              <topCExternalLink
                  v-if="menu.feature_id == 'external_links' + menu.shop_menu_id"
                  :menu="menu"
                  @set="NextRouting"
                ></topCExternalLink>
            </div>

            <!-- SHOPPING -->
            <TopShopping
              v-if="init.sid == 905017 || init.sid == 904980 || init.sid == 4980 && menuloadflg == 1"
              @set="toShopping"
            ></TopShopping>

          </slick>
        </div>
      </div>
    </div>
  <GlobalFooter v-if="init.preview == 1 && init.menu_data" :menus="init.menu_data" />
  </v-content>
</template>

<script>
import Slick from "vue-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import TopLPage from "@/views/top/topLPage.vue";
import TopShop from "@/views/top/topShop.vue";
import TopCoupon from "@/views/top/topCoupon.vue";
import TopPoint from "@/views/top/topPoint.vue";
import TopCStamp from "@/views/top/topCStamp.vue";
import TopBooking from "@/views/top/topBooking.vue";
import TopDigitalTicket from "@/views/top/topDigitalTicket.vue";
import TopNotification from "@/views/top/topNotification.vue";
import TopShopping from "@/views/top/topShopping.vue";
import topCExternalLink from "@/views/top/topCExternalLink.vue";
import GlobalFooter from "@/components/global_footer.vue"
export default {
  components: {
    Slick,
    TopLPage,
    TopShop,
    TopCoupon,
    TopPoint,
    TopCStamp,
    TopBooking,
    TopDigitalTicket,
    TopNotification,
    GlobalFooter,
    TopShopping,
    topCExternalLink,
  },
  data() {
    return {
      init: [],
      slickOptions: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "28px",
        arrows: false
      },
      menu_list: null,
      lpage: null,
      shop: null,
      coupon: null,
      stamp: null,
      digitalticket: null,
      digital_purchased_list: null,
      notification: null,
      point: null,
      booking: null,
      menuloadflg: 0,
      badgeParams: {},
      registration_list: null,
    };
  },
  async created() {
    console.warn('start top-c');
    //画面縦サイズを取得
    var wh = window.innerHeight
    if(wh > 590){
      const elem = document.getElementById('app');
      elem.classList.add('bh');
    }

    //createdで、localstrageから全ての保存情報を取得しinitに格納
    this.init = this.storageGet("*");

    /* axiosで画面表示時、トップメニューリスト情報取得API実行 */
    this.menu_list = await this.apiCallIfUnauthorizedReturnsErrorCode(
      "/user/topmenu/2",
      {
        params: {
          sid: this.init.sid,
          service_cd: this.init.service_cd,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          preview: this.$route.query.preview, //isPreview Getパラメータがある場合は、APIにisPreviewを渡す
          // 20201105 外部リンク追加対応 cyber 董 start
          createFlag: this.$route.query.createFlag, //未保存が新規プレビューデータ表示・非表示フラグ
          // 20201105 外部リンク追加対応 cyber 董 end
        }
      },
      "get"
    );

    if (!this.menu_list || !this.menu_list.menus) return false;

    var api_url,
      api_res,
      save_name = null; // 事前に各APIを叩く為の、データの格納先を用意

    for (var i = 0; i < this.menu_list.menus.length; i++) {
      var api_req = {
        params: {
          sid: this.init.sid,
          service_cd: this.init.service_cd,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          preview: this.$route.query.preview, //preview Getパラメータがある場合は、APIにisPreviewを渡す
          createFlag: this.$route.query.createFlag, //未保存が新規プレビューデータ表示・非表示フラグ
        }
      };

      // トップメニューリスト情報取得API（B,C)の結果に応じて、ループ処理で順番にAPIを叩く。
      console.warn('feature_id : ' + this.menu_list.menus[i].feature_id);
      console.warn('device_uid : ' + JSON.stringify(this.init.device_uid));
      switch (this.menu_list.menus[i].feature_id) {
        case "info":
          // Object.assign(api_req.params, { shop_id: null, page: 1 });
          api_url = "/user/shops";
          save_name = "shop";
          api_req.params.feature_id = this.menu_list.menus[i].feature_id;
          api_req.params.location = this.menu_list.menus[i].location ?? 0;

          break;
        case "coupon":
          Object.assign(api_req.params, { shop_id: null, page: 1 });
          api_url = "/user/coupons";
          save_name = "coupon";
          break;
        case "stamp":
          Object.assign(api_req.params, { shop_id: null, page: 1, limit: 1 });
          api_url = "/stamp/top-list";
          save_name = "stamp";
          break;
        case "digital_ticket":
          Object.assign(api_req.params, {
            shop_id: this.init.sid,
            is_bought: 0,
            page: 1
          });
          api_url = "/digitalticket/list";
          save_name = "digitalticket";
          break;
        // 購入済みタブ側の情報は、購入済みタブが押された時に取得するよう、そちらに移す(API処理分散したい方針だったはず)
        case "notification":
          Object.assign(api_req.params, { shop_id: null, page: 1 });
          api_url = "/user/notifications";
          save_name = "notification";
          break;
        case "point":
          api_url = "/point";
          save_name = "point";
          break;
        case "booking":
          Object.assign(api_req.params, { page: 1, limit: 1 });
          api_url = "/user/booking";
          save_name = "booking";
          break;
        case "auth":
          // 会員登録の情報を設定する
          this.registration_list = this.menu_list.menus[i];
          // console.warn(this.registration_list);
          continue;
        default:
          continue;
      }
      //console.log(this[save_name]);
      api_res = await this.apiCallIfUnauthorizedReturnsErrorCode(api_url, api_req);
      if (!api_res) continue; // resが返らない時のエラーは共通エラー処理でやるので、処理中断だけで良い。
      this.$set(this, save_name, api_res);
    }
    this.menuloadflg = 1
  },
  methods: {
    fetchData(){ //init apiが読み込まれた場合initに値を入れる
        this.init = this.storageGet('*')

        //バッジ数をネイティブに送る
        this.badgeParams['_vue_param'] = true;
        this.badgeParams['menu_data'] = this.init.menu_data;
        this.badgeParams['total_unread_count'] = this.init.total_unread_count;
        //console.log(this.badgeParams)
        this.nativePost(this.badgeParams);
    },
    async NextRouting(
      destination,
      feature_id,
      screen_id,
      transition_type,
      top_type,
      cassette_id,
      transition_info,
      page_title,
    ) {
      try {
        // プレビューの場合は通常遷移させる
        if (this.$route.query.preview) {
          return window.location.href = destination + '?sid=' + this.init.sid + '&preview=1';
        }
        //タップAPI
        var tap_req = {
          sid: this.init.sid,
          service_cd: this.init.service_cd,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          top_type: top_type,
          cassette_id: cassette_id,
          transition_type: transition_type,
          transition_info: transition_info
        };
        var tap_res = await this.apiCallIfUnauthorizedReturnsErrorCode("/user/tap", tap_req, "post");
        if (!tap_res) return false;

        //ルーティングAPI
        var tonative_req = {
          destination: destination,
          feature_id: feature_id,
          screen_id: screen_id,
          transition_type: transition_type,
          page_title: page_title,
        };

        if (!this.screenTransition(tonative_req)) {
          this.loading = false;
          // 遷移失敗・遷移情報が変な時もエラー表示があった方が良い？
          this.callDialog(
            "遷移エラー",
            "遷移情報が不正です。再度アクセスして下さい。",
            2
          );
        }
      } catch (e) {
        this.callDialog(
          "通信エラー",
          "ネットワークの状態が不安定です。再度お試しください",
          3
        );
      }
    },
    toShopping(){
      var destination = 'https://www.shokutu.com/'
      var feature_id = 1
      var screen_id = "top"
      var transition_type = 4
      var top_type = 1
      var cassette_id = 1
      var transition_info = "shopping"
      var page_title = "SHOPPING"

      this.NextRouting(
        destination,
        feature_id,
        screen_id,
        transition_type,
        top_type,
        cassette_id,
        transition_info,
        page_title,
      )
    }
  },
  watch: {
    '$root.init_flg': 'fetchData',
    menuloadflg: function() {
      this.$refs.slick.destroy();
      this.$nextTick(() => {
        this.$refs.slick.create();
      });
    }
  }
};
</script>

<style scoped>
/* ページレイアウト */
.wrap {
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center !important;
  background: -moz-linear-gradient(-58deg, #cee6ff, #f8cff5);
  background: -webkit-linear-gradient(-58deg, #cee6ff, #f8cff5);
  background: linear-gradient(148deg, #cee6ff, #f8cff5);
}

.body {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.body::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}
.logo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}
.slider {
  margin-top: 70px;
}
.v-application .slider {
  margin-bottom: 60px;
}
.v-application.bh .slider {
  margin-bottom: 0px;
}

</style>
<style>
  .v-application.bh {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

</style>
