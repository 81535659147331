<template>
  <v-content>
    <!-- クーポン系 -->
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px;height:30px"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">COUPON</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >
            すべて表示
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        <v-card width="295px" height="403px" class="elevation-6" v-if="coupon.total_coupon_count != 0 && coupon.data">
          <v-img :src="coupon.data[0].image_url" height="295px"></v-img>
          <v-chip v-if="coupon.data[0].distribution_type == 2" x-small class="ml-4" color="#EB762F" dark style="position: relative;top: -12px;">初回DL特典</v-chip>
          <v-chip v-if="coupon.data[0].distribution_type == 3" x-small class="ml-4" color="#F26474" dark style="position: relative;top: -12px;">お誕生日特典</v-chip>

          <p class="body-2 mx-4 mb-1 mt-5" style="position: relative;">
            {{coupon.data[0].title}}
          </p>
          <v-row align="end" no-gutters class="mx-4">
            <v-col cols="9" style="font: normal 9px/13px 'Roboto';">
              <p class="ma-0">有効期限：{{coupon.data[0].disp_date}}</p>
              <p class="ma-0">対象店舗：{{coupon.data[0].shop_names}}</p>
            </v-col>
            <v-col cols="3" class="text-right" v-if="coupon.data[0].is_read == 0">
              <v-chip x-small color="#9D9D9D" style="color: #FFFFFF;">未読</v-chip>
            </v-col>
          </v-row>
        </v-card>
        <v-card width="295px" height="403px" class="elevation-6" v-if="coupon.total_coupon_count == 0">
          <v-img src="@/assets/topc3.png" class="text-center">
            <v-row align="center" justify="center" style="height: 463px; font: normal 14px/22px 'Roboto'; color: #9D9D9D;">
              現在利用できる<br>クーポンはありません
            </v-row>
          </v-img>
        </v-card>
        <div class="text-center mt-4" v-if="coupon.total_coupon_count > 0">
          <p class="mb-0 maincolor" style="font: bold 11px/16px 'Roboto';">現在ご利用可能なクーポンが{{coupon.total_coupon_count}}枚あります</p>
          <a class="maincolor" style="font: bold 9px/16px 'Roboto';" @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">すべて表示</a>
        </div>
      </v-card>
    </div>

  </v-content>
</template>

<script>
export default {
  props: ['coupon', 'menu'],
  created() {
  },
  methods: {

  },
}
</script>