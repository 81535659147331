<template>
  <v-content>
    <!-- 外部リンク -->
    <div class="px-3 pb-4">
      <v-row align="end" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px;height:30px" /></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 12px/22px '游ゴシック体';">{{menu.title.substring(0,10)}}</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Hiragino Kaku Gothic Pro,W3';">
          <v-btn
           rounded class="maincolor" elevation="2" x-small width="120px" height="30px" color="#FFFFFF"
           @click.stop="$emit('set', menu.link_external_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
            詳細を見る
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        <div width="295px" height="260px">
          <v-img :src="menu.link_image_path" @click.stop="$emit('set', menu.link_external_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
           width="295px" height="260px" class="v-border-radius"></v-img>
        </div>
        <div class="mx-4" style="text-align: center; margin-top: 30px; margin-bottom: 21px; height: 34px;">
          <p style="font: bold 14px/20px '游ゴシック体'; white-space: pre-wrap; overflow: hidden;" :style="{color: menu.link_headline_color}">{{menu.link_headline | headLineClip}}</p>
        </div>
        <div class="mx-4 mt-0" style="text-align: center; height: 22px; margin-bottom: 34px; ">
          <p v-if="menu.link_title_view_flg == 1" style="font: bold 24px '游ゴシック体'; color: #333333;" :style="{color: menu.link_title_color}">{{menu.link_title.substring(0,10)}}</p>
        </div>
        <v-row align="start" justify="center" no-gutters class="mt-0">
          <v-btn 
            rounded
            class="maincolor-bg"
            style="font: bold 14px/24px 'Roboto'; height: 32px; width: 200px;"
          @click.stop="$emit('set', menu.link_external_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
            {{menu.link_button_name.substring(0,12)}}
          </v-btn>
        </v-row>
      </v-card>
    </div>

  </v-content>
</template>

<script>
export default {
  props: ['menu'],
  created() {
  },
  methods: {
    getDetailUrl: function() {
      //this.detail_url =  this.getDomain() + 'link/detail?id=' + this.link.id
    }
  },
  filters: {
    headLineClip (value) {
      if (value) {
        let tmpValue = '';
        value.split(/\n/g).forEach(function (val, idx, arr) {
          if (val.length > 17) {
            val = val.substring(0, 17) + '\n' + val.substring(17);
          }
          if (idx != arr.length - 1){
            val = val + '\n';
          }
          tmpValue = tmpValue.concat(val.toString());
        });
        if (tmpValue.split(/\n/g).length > 1) {
          value = tmpValue.split(/\n/g)[0] + '\n' + tmpValue.split(/\n/g)[1];
        } else {
          value = tmpValue.split(/\n/g)[0];
        }
      }
      return value;
    }
  }
}
</script>
<style>
  .v-border-radius {
    border-radius: 16px 16px 0 0;
  }

</style>
