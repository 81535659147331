<template>
  <v-content>
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">POINT</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >
            すべて表示
          </v-btn>
        </v-col>
      </v-row>

      <v-card width="295px" height="463px" class="elevation-6" v-if="point">
        <div style="height: 0px; position: relative; top: -4px; z-index: 2;" v-if="point.rank_img_path">
          <v-img class="ml-2" :src="point.rank_img_path" width="50px" height="50px"></v-img>
        </div>
        <v-row align="center" justify="center" class="maincolor-bg" no-gutters style="width: 295px; height: 34px; border-radius: 8px 8px 0px 0px; position: relative; z-index: 1;">
          <p class="mb-0 text-center" style="font: bold 12px/20px 'Roboto';">
            現在までの取得ポイント
          </p>
        </v-row>
        <div style="width: 295px; height: 31px;" v-if="point.initial_download_benefits">
          <p class="mt-3 mb-0 text-center maincolor" style="font: bold 12px/19px 'Roboto';">
            初回ダウンロード特典<br>
            {{point.initial_download_benefits}}pointプレゼント
          </p>
        </div>

        <div style="width: 295px; height: 20px;" v-else>
        </div>

        <!-- 減算方式 -->
        <div class="my-12 text-center" v-if="point.type == 1">
          <div class="text-center" style="color: #000000; font: bold 20px/24px 'Oswald';">
            <p class="mb-2" style="font: bold 80px/80px 'Oswald';"><AnimatedNumber :value="num"></AnimatedNumber></p>
            <p class="mb-0" style="font: bold 24px/24px 'Oswald';">point</p>
          </div>
        </div>

        <!-- コンプリート方式 -->
        <div class="mt-3 text-center" v-if="point.type == 2">
          <v-progress-circular :rotate="-90" :size="200" :width="15" :value="value" :color="maincolor">
            <div class="text-center" style="color: #000000; font: bold 20px/24px 'Oswald';">
              <span style="font: bold 54px/54px 'Oswald';"><AnimatedNumber :value="num"></AnimatedNumber></span><br>
              <span class="maincolor" style="font: normal 30px/40px 'Roboto'; position: relative; top: -4px;">/</span>
              <span>{{point.grant_point}}</span>
              <span style="font: bold 12px/16px 'Oswald';">point</span>
              <p class="mb-0 maincolor" style="font: bold 9px/13px 'Roboto';">
                コンプリートまであと<br>
                <span style="font: bold 20px/24px 'Oswald';">{{point.grant_point - point.point}}</span>
                <span style="font: bold 12px/24px 'Oswald';">point</span>
              </p>
            </div>
          </v-progress-circular>
        </div>

        <div class="mx-3 mt-3 text-center" style="height: 22px;">
          <hr color="#C8C7CC" size="1px">
          <p class="mb-0 text-center" style="color: #B4B4B4; font: bold 11px/16px 'Roboto';">ポイント有効期限：{{point.expiration_date}}</p>
          <hr color="#C8C7CC" size="1px">
        </div>

        <v-row align="center" justify="center" no-gutters class="mt-2" v-if="point.rank_text">
          <span style="color: #B4B4B4; font: bold 9px/16px 'Roboto';" class="mr-3">
            現在のランク
          </span>
          <span style="color: #333333; font: bold 16px/24px 'Roboto';">
            {{point.rank_text}}
          </span>
        </v-row>

        <v-footer absolute align="start" justify="center" no-gutters class="" style="background-color: #F9F9F9; width: 100%;">
          <v-row>
          <v-col cols="8" style="color: #9D9D9D; font: bold 8px/10px 'Roboto';" class="my-1 mx-0 py-0">
            <p class="mb-0">ユーザー情報</p>
            <v-row class="mt-2" align="start" justify="center" no-gutters>
              <v-col cols="3">ID</v-col>
              <v-col cols="9" style="color: #333333; font: bold 10px/10px 'Roboto';">{{point.user_id}}</v-col>
            </v-row>
            <v-row class="mt-2" align="start" justify="center" no-gutters>
              <v-col cols="3">NAME</v-col>
              <v-col v-if="point.user_name" cols="9" style="color: #333333; font: bold 10px/10px 'Roboto';">{{point.user_name}} さん</v-col>
              <v-col v-else cols="9" style="color: #333333; font: bold 10px/10px 'Roboto';">ゲスト さん</v-col>
            </v-row>
            <p class="mb-0 mt-1" style="font: normal 8px/11px 'Roboto';">
              ポイント取得の際は、QRコードをスタッフへご提示ください
            </p>
          </v-col>
          <v-col cols="4" class="my-1 mx-0 py-0" @click="showQRDialog()">
            <!-- QRコードを表示：生成URL未設定 -->
            <qrcode :value="qrcodeurl" :options="{ width: 70, margin: 1 }"></qrcode>
          </v-col>
          </v-row>
        </v-footer>

      </v-card>
    </div>

    <QRDialog ref="qrDialog" :qrdata="qrcodeurl"></QRDialog>

  </v-content>
</template>

<script>
import AnimatedNumber from "@/components/AnimatedNumber.vue";
import QRDialog from '@/views/point/QRDialog.vue'

export default {
  components: {
    AnimatedNumber,
    QRDialog
  },
  props: ['point', 'menu', 'init'],
  data () {
    return {
      value: 0,
      num: 0,
      maincolor: this.$root.maincolor,
      qrcodeurl: null,
    }
  },
  created() {
    this.qrcodeurl = "https://" + location.host + "/points/" + this.init.user_id + "/edit?shopId=" + this.init.sid + "&shopName=" + this.init.shop_name
  },
  methods: {
    showQRDialog() {
      this.$refs.qrDialog.open();
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.value = (this.point.point / this.point.grant_point) * 100;
      this.num = this.point.point;
    }, 1500);
  },
}
</script>