<template>
  <v-content>
    <!-- NEWS -->
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px;height:30px"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">NEWS</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Hiragino Kaku Gothic Pro';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >
            すべて表示
          </v-btn>
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6" v-if="notification.data != ''">
        <div class="mt-2 pt-2" style="width: 295px; height: 463px; overflow-y: scroll;">

          <v-row align="start" justify="center" no-gutters style="width: 265px;" class="ml-2 mb-4" v-for="(item, i) in notification.data" :key="i">
            <v-col cols="5">
              <v-img  v-if="item.image_url" :src="item.image_url" lazy-src="@/assets/nophoto.png" class="rounded elevation-2">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
                <div class="newribbon" v-if="item.new_flg == 1">
                  <span>NEW</span>
                </div>
                <v-chip x-small dark color="#9D9D9D" class="viewflg_label" v-if="item.is_read == 0">未読</v-chip>
              </v-img>
              <v-img v-else src="@/assets/notification/noimg@2x.png" lazy-src="@/assets/nophoto.png" class="rounded elevation-2" width="115" height="115">
                <div class="newribbon" v-if="item.newflg == 1">
                  <span>NEW</span>
                </div>
                <v-chip x-small dark color="#9D9D9D" class="viewflg_label" v-if="item.is_read == 0">未読</v-chip>
              </v-img>
            </v-col>
            <v-col cols="7">
              <div class="ml-2" style="height: 110px;">
                <v-btn rounded outlined disabled x-small style="font: bold 12px/20px '游ゴシック体';" width="146px">
                  {{item.type_name}}
                </v-btn>
                <p class="mb-0 mt-2" style="font: normal 11px/20px 'YuGothic'; width: 146px; height: 52px;">
                  {{item.title}}
                </p>
              </div>
            </v-col>
            <v-col cols="12" class="mt-2 mb-4" style="font: normal 9px/13px 'YuGothic';">
              配信日：{{item.start_date}}
              対象店舗：{{item.shop_name}}
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-card width="295px" height="463px" class="elevation-6" v-else>
        <div class="mt-2" style="width: 295px; height: 463px;">
          <v-img src="@/assets/topc3.png" class="text-center">
            <v-row align="center" justify="center" style="height: 463px; font: normal 14px/22px '游ゴシック体'; color: #9D9D9D;">
              現在配信されている<br>お知らせはありません
            </v-row>
          </v-img>
        </div>
      </v-card>
    </div>
  </v-content>
</template>

<script>
export default {
  props: ['notification', 'menu'],
  created() {
  },
  methods: {

  },
}
</script>

<style scoped>
.rounded {
	border-radius: 10px;
}
.viewflg_label {
	position: absolute;
	left: 5px;
	bottom: 5px;
}
</style>