<template>
  <v-content>
    <!-- 店舗検索 -->
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img src="@/assets/shopping_icon.svg"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">LP</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        店舗検索用
        <div>
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
            @click.stop="$emit('set', button_url, feature_id, screen_id, transition_type, top_type, cassette_id, transition_info, title)"
            >
              登録はこちら
          </v-btn>
        </div>
      </v-card>
    </div>

  </v-content>
</template>

<script>
export default {
  data() {
    return {
      button_url: "/user/shops",
      feature_id: "info",
      screen_id: "info_shops",
      transition_type: 2,
      top_type: 1,
      cassette_id: 350,
      transition_info: 'info_shops',
      title: "店舗検索"
    };
  },
  // props: ['lpage', 'menu'],
  created() {
  },
  methods: {

  },
}
</script>