<template>
  <v-content>
    <!-- ランディングページ -->
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img src="@/assets/shopping_icon.svg"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">LP</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Roboto';">
        </v-col>
      </v-row>
      <v-card width="295px" height="463px" class="elevation-6">
        ランディングページ用
        <div>
          <!-- <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
            @click.stop="$emit('set', button_url, feature_id, screen_id, transition_type, top_type, cassette_id, transition_info, title)"
            >
              登録はこちら
          </v-btn> -->
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
            @click.stop="$emit('set', menu.button_url, menu.screen_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
            >
              登録はこちら
          </v-btn>
        </div>
      </v-card>
    </div>

  </v-content>
</template>

<script>
export default {
  data() {
    return {
      // button_url: "http://192.168.11.100:9003/sp/login/index",
      // feature_id: "registration",
      // screen_id: "registration",
      // transition_type: 2,
      // top_type: 1,
      // cassette_id: 352,
      // transition_info: "registration",
      // title: "会員登録",

      button_url: null,
      feature_id: null,
      screen_id: null,
      transition_type: null,
      top_type: null,
      cassette_id: null,
      transition_info: null,
      title: null
    };
  },
  props: ['menu'],
  created() {
    if(this.menu){
      this.$set(this, 'button_url', this.menu.button_url);
      this.$set(this, 'feature_id', this.menu.screen_id);
      this.$set(this, 'screen_id', this.menu.screen_id);
      this.$set(this, 'transition_type', this.menu.transition_type);
      this.$set(this, 'top_type', this.menu.top_type);
      this.$set(this, 'transition_info', this.menu.transition_info);
      this.$set(this, 'title', this.menu.title);
    }
  },
  methods: {

  },
}
</script>