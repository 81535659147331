<template>
  <v-content>
    <!-- デジチケ系 -->
    <div class="px-3 pb-4">
      <v-row align="center" justify="center" no-gutters class="mb-3" style="width:295px; position: relative; z-index: 3;">
        <v-col cols="2" class="d-flex align-end"><img :src="menu.title_icon_url" style="width:30px;height:30px"></v-col>
        <v-col cols="5" style="position: relative; left: -15px;">
          <span style="font: bold 13px/22px 'Roboto';">DIGITAL TICKETS</span>
        </v-col>
        <v-col cols="5" class="text-right" style="font: bold 12px/16px 'Hiragino Kaku Gothic Pro';">
          <v-btn rounded elevation="2" x-small width="120px" height="30px" color="#FFFFFF" class="maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >
            すべて表示
          </v-btn>
        </v-col>
      </v-row>

      <v-card width="295px" height="463px" class="elevation-6">
        <v-row align="start" justify="center" no-gutters class="pt-3" style="font: bold 12px/24px '游ゴシック体';" v-if="buyflg==0">
          <v-col cols="6" class="pl-3">
            <v-btn width="135px" rounded small dark class="maincolor-bg">販売中チケット</v-btn>
          </v-col>
          <v-col cols="6" class="text-right pr-3">
            <v-btn width="135px" rounded small color="#FFFFFF" class="elevation-0 maincolor" @click="changeTicket(1)">
              購入済みチケット
            </v-btn>
          </v-col>
        </v-row>

        <v-row align="start" justify="center" no-gutters class="pt-3" style="font: bold 12px/24px '游ゴシック体';" v-if="buyflg==1">
          <v-col cols="6" class="pl-3">
            <v-btn width="135px" rounded small color="#FFFFFF" class="elevation-0 maincolor" @click="changeTicket(0)">販売中チケット</v-btn>
          </v-col>
          <v-col cols="6" class="text-right pr-3">
            <v-btn width="135px" rounded small dark class="maincolor-bg">
              購入済みチケット
            </v-btn>
          </v-col>
        </v-row>

        <div class="mt-2 px-3" style="width: 295px; height: 413px; overflow-y: scroll;" v-if="digitalticket">
          <!-- 販売中側デジチケ、デザイン -->
          <div v-if="buyflg==0">
            <v-card class="overflow-hidden mb-5" v-for="(item, i) in digitalticket.data" :key="i">
              <v-row class="" no-gutters>
                <v-col cols="3" class="text-center ma-0 pa-0">
                  <div class="py-2" style="background-color: #35d4ca;" v-if="item.limit_type != null">
                    <p class="overline mb-0 white--text">- {{item.limit_type_text}} -</p>
                    <p class="title white--text mb-0" style="line-height: 1.2rem;">
                      {{item.limit_number}}<span class="overline">名</span>
                    </p>
                  </div>
                  <div class="py-2">
                    <div class="pt-6 text-center">
                      <img src="@/assets/digital_ticket/digitalticket_ticket.svg" style="display: inline;">
                    </div>
                    <v-chip outlined x-small class="ma-0 px-2" color="#9d9d9d">
                      {{item.ticket_type_text}}
                    </v-chip>
                  </div>
                </v-col>
                <v-col
                  class="pa-3"
                  cols="9"
                  v-bind:style="{
                    'background-size': 'cover',
                    'background-image': 'url(' + item.image_path + ')',
                  }"
                >
                  <p class="subtitle-2 font-weight-bold mb-0" style="line-height:14px;">
                    {{item.title | truncate(16)}}
                  </p>
                  <div class="headline d-inline mr-2">
                    {{item.price}}<span class="subtitle-2">円</span>
                  </div>
                  <v-chip
                    outlined
                    x-small
                    class="inline-block ma-0 px-2"
                    color="#000"
                  >
                    税込
                  </v-chip>
                  <p class="fontsize9 pt-1 mb-2" style="line-height: 0.8rem;">
                    {{item.terms_of_use_text | truncate(75)}}
                  </p>
                  <v-alert
                    dense
                    dark
                    class="text-center fontsize9 pa-0 mb-0"
                    color="#E55497"
                    >{{item.sales_promotion_text}}</v-alert
                  >
                </v-col>
              </v-row>
            </v-card>
          </div>

          <div v-if="buyflg==1">
            <v-card class="overflow-hidden mb-5" v-for="(item, i) in digitalticket_buys.data" :key="i">
              <v-row class="" no-gutters align="center">
                <v-col cols="3" class="text-center ma-0 pa-0">
                  <div class="">
                    <div class="">
                      <img src="@/assets/digital_ticket/digitalticket_ticket.svg" />
                    </div>
                    <v-chip outlined x-small class="ma-0 px-2" color="#9d9d9d">
                      {{item.ticket_type_text}}
                    </v-chip>
                  </div>
                </v-col>
                <v-col
                  class="pa-3"
                  cols="9"
                  v-bind:style="{
                    'background-size': 'cover',
                    'background-image': 'url(' + item.image_path + ')',
                  }"
                >
                  <p class="subtitle-2 font-weight-bold mb-0">
                    {{item.title | truncate(16)}}
                  </p>
                  <p class="fontsize9 pt-2 mb-2" style="line-height: 0.8rem;">
                    {{item.terms_of_use_text | truncate(75)}}
                  </p>
                  <v-btn rounded depressed class="maincolor-bg default_button w90"
                    >チケットを使用する</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </div>

        </div>

        <div class="mt-2" style="width: 295px; height: 413px; overflow: hidden;" v-if="digitalticket == null && buyflg == 0">
          <v-img src="@/assets/topc3.png" class="text-center" height="413px">
            <v-row align="center" justify="center" style="height: 413px; font: normal 14px/22px '游ゴシック体'; color: #9D9D9D;">
              現在販売中のチケットはありません
              <!-- 現在購入済みのチケットはありません -->
            </v-row>
          </v-img>
        </div>

        <div class="mt-2" style="width: 295px; height: 413px; overflow: hidden;" v-if="!digitalticket_buys.data && buyflg == 1">
          <v-img src="@/assets/topc3.png" class="text-center" height="413px">
            <v-row align="center" justify="center" style="height: 413px; font: normal 14px/22px '游ゴシック体'; color: #9D9D9D;">
              現在購入済みのチケットはありません
            </v-row>
          </v-img>
        </div>

      </v-card>
    </div>

  </v-content>
</template>

<script>
export default {
  props: ['digitalticket', 'menu', 'init'],
  data () {
    return {
      buyflg: 0,
      digitalticket_buys: null
    }
  },
  mounted() {
      try {
        // 購入済みデジチケAPI実行
        var dtreq = { params:{
          'sid': this.init.sid,
          'service_cd': this.init.service_cd,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'shop_id': this.init.sid,
          'is_bought': 1,
          'page': 1,
        }}
        var res = this.apiCall('/digitalticket/list', dtreq);

        this.$set(this, 'digitalticket_buys', res);
        console.log(this.digitalticket_buys)
      } catch(e) {
        return false;
      }
  },
  methods: {
    changeTicket(flg){
      this.buyflg = flg
    },

  },
}
</script>